/* eslint-disable no-useless-escape */
// I have added [name]Invalid after each regex so you can understand why it is invalid and having it
//  stored in the same object as the regex makes it easier to maintain :-)
export const regexMatcherType = {
    personName: /^[a-zA-Z\s'-]{0,50}$/g,
    personNameInvalid: `Your input should only contain letters, spaces, apostrophes ('), or hyphens (-). Also, it should not be longer than 50 characters.`,
    businessName: /^[\w'@!_.&,\s-]{0,100}/g,
    businessNameInvalid: `Your input should only contain letters, numbers, hyphens (-), periods (.), or underscores (_). 
                            Also, it should not be longer than 25 characters.`,
    businessABN: /^[\d\s-]{0,15}/g,
    businessABNInvalid: `Your input should only contain numbers, spaces, or hyphens (-). Also, it should not be longer than 15 characters.`,
    textInput: /^([a-zA-Z0-9,!@#$%^&*()}{_?\s.'-])*/g,
    textInputInvalid: `Your input can contain any letters, numbers, spaces, or special characters like !, @, #, $, %, ^, &, *, (, ), }, {, _, ?, ., ', or -.`,
    endpointUrl: /^(https):\/\/(www\.)?[a-zA-Z0-9:%._+#=-]{1,256}[a-zA-Z0-9.]{0,6}\b([a-zA-Z0-9:%._+#=?&\/\/-]*)/g,
    endpointUrlInvalid: `Your input should be a website address that starts with ‘https://’. It can optionally have ‘www.’ after ‘https://’. 
                        The rest of the address can contain letters, numbers, or special characters like :, %, ., _, +, #, =, ?, &, /, or -.`,
    userName: /^[a-zA-Z0-9-._]{0,25}$/g,
    userNameInvalid: `Your input should only contain letters, numbers, hyphens (-), periods (.), or underscores (_). 
                        Also, it should not be longer than 25 characters.`,
    // The = symbol is not allowed on club-api
    password: /^(?!.*=)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^*()&_?-])[A-Za-z\d!@#$%^*()&_?-]{10,20}$/g,
    passwordInvalid: `Your input should be 10 to 20 characters long. It must contain at least one uppercase letter, one lowercase letter, one number, 
                        and one special character from this set: !, @, #, $, %, ^, *, (, ), _, or -. It should not contain any equals sign (=).`,
    address: /^[a-zA-Z0-9]{1,}[a-zA-Z0-9,.#'/\s*a-zA-Z0-9-]{1,250}/g,
    addressInvalid: `It can contain letters, numbers, commas (,), periods (.), hash signs (#), apostrophes ('), slashes (/), spaces, or hyphens (-). 
                        It must be longer than 2 characters and must not exceed 250 characters.`,
    city: /^[a-zA-Z]{1,}[a-zA-Z'/\s-]{1,250}/g,
    cityInvalid: `Your input can contain any combination of letters, apostrophes ('), slashes (/), spaces, or hyphens (-). The total length should not exceed 250 characters.`,
    postCode: /^[0-9]{4}/g,
    postCodeInvalid: `Your input should contain only four numbers.`,
    // we should be using the following regex. However it was too strict with our current implementation where we prevent typing
    // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
    email: /^([a-zA-Z0-9!#$%&'*+-/=?^_`{-]+@[a-zA-Z0-9!#$%&'*+-/=?^_`{-]+\.[a-zA-Z]{1,}\.?){0,1}/g,
    emailInvalid: `Your input can be an email address. It should have letters, numbers, or special characters before and after the ‘@’ symbol. 
                    The email address should end with a period (.) followed by letters.`,
    ipAddress: /^https:\/\/(www\.)?((\d{1,3}.)?(\d{1,3}.)?(\d{1,3}.)?\d{1,3}){1,}[a-zA-Z0-9.]{0,6}\b([a-zA-Z0-9:%._+#=?&\/\/-]*)/g,
    ipAddressInvalid: `Your input should be a website address that starts with ‘https://’. It can optionally start with ‘www.’ 
                        followed by a series of numbers separated by periods (like an IP address). 
                        The rest of the address can contain letters, numbers, or special characters.`
};

export function validateInput(regexMatcher, input) {
    if (!Boolean(input)) {
        return false;
    }

    const result = input.match(regexMatcher);
    if (result !== null) {
        return result[0] === input;
    }

    return false;
}

// This helperFunction was made an Export otherwise it takes up too much space
export function validatePasswordInput(stringPassword){
    // The user should know what rule is not being met. An "invalid" message doesn't explain
    //  why the user's password is invalid
    if (stringPassword.length < 10 || stringPassword.length > 20) {
        return "Error: The password must be 10 to 20 characters long.";
    } else if (!/[A-Z]/.test(stringPassword)) {
        return "Error: The password must contain at least 1 UPPERCASE character [A-Z].";
    } else if (!/[a-z]/.test(stringPassword)) {
        return "Error: The password must contain at least 1 lowercase character [a-z].";
    } else if (!/[0-9]/.test(stringPassword)) {
        return "Error: The password must contain at least 1 number [0-9].";
    } else if (!stringPassword.split('').some(char => "!@#$%^*()-&_?".includes(char))){
        return "Error: The password must contain at least 1 symbol e.g. one of !@#$%^*()-&_?";
    } else if(!regexMatcherType.password.test(stringPassword)){
        return "Error: Your password includes an invalid symbol.";
    }
}

export const EMAIL_LENGTH = 254;

export function longerThan(maximumLength, input) {
    return input.length > maximumLength;
}

export function fileUp(file) {
    if(file === 'largeLogoImage'){
        return false;
    }else{
        return true;
    }
}

export function capitalizeFirstLetter(str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}
