import * as crypto from "crypto";
import axios from "axios";

const regions = {
  localhost: "dev",
  "partner-portal-sit.api.dlp-k8s-npe.testservicensw.net": "sit",
  "partner-portal-uat.testservicensw.net": "uat",
  "partner-portal-it3.testservicensw.net": "it3",
  "partner-portal-it4.testservicensw.net": "it4",
  "partner-portal-it1.testservicensw.net": "it1",
  "partner-portal-load.npe-dlp-snsw.net": "load",
  "partner-portal-it6.npe-dlp-snsw.net": "bbounty",
  "partner-portal.service.nsw.gov.au": "prod",
};

export const releaseType = regions[window.location.hostname];

export const providerRoles = ["provideradmin", "provideruser"];
export const systemRoles = ["systemadmin", "systemuser"];
export const userRoles = providerRoles.concat(systemRoles);

export const userRolesNames = [
  "Admin",
  "Member",
  "System Admin",
  "System User",
];

export const auth0userDetailsKey = "https://user-details";

export const idpDomain = {
  dev: "https://partners-psm.login.testservicensw.net",
  sit: "https://identity-uat.api.dlp-ext.testservicensw.net/digitallicence",
  it1: "https://club-auth-uat.testservicensw.net",
  uat: "https://partners-psm.login.testservicensw.net",
  it3: "https://identity-it3.testservicensw.net/digitallicence",
  it4: "https://identity-it4.testservicensw.net/digitallicence",
  load: "https://identity-load.npe-dlp-snsw.net/digitallicence",
  bbounty: "https://identity-it6.npe-dlp-snsw.net/digitallicence",
  prod: "https://partners-login.service.nsw.gov.au"
};

export const cluburl = {
  dev: "http://localhost:4000/club",
  sit: "https://partner-portal-sit.api.dlp-k8s-npe.testservicensw.net/club",
  uat: "https://partner-portal-uat.testservicensw.net/club",
  it1: "https://partner-portal-it1.testservicensw.net/club",
  load: "https://partner-portal-load.npe-dlp-snsw.net/club",
  bbounty: "https://partner-portal-it6.npe-dlp-snsw.net/club",
  prod: "https://partner-portal.service.nsw.gov.au/club",
};

function getIDPDomain() {
  const environment = idpDomain[releaseType];
  return environment;
}

function getClientId() {
  if (releaseType === 'prod') {
    return "EyjrK4tcVu09pPbjhJW7E1YkL75td5W6"
  } else {
    return "7KzL5TNb6oy2nQZrGlQ5LLG8wlW28QlB";
  }
}

export const Config = {
  authorizationEndpoint: getIDPDomain() + "/authorize",
  registrationEndpoint: getIDPDomain() + "/Account/Register",
  tokenEndpoint: getIDPDomain() + "/oauth/token",
  jwks_uri: getIDPDomain() + "/.well-known/openid-configuration/jwks",
  clientId: getClientId(),
  redirectUri: cluburl[releaseType] + "/callback",
  helpUri: "/help/index.html",
  logoutEndpoint: getIDPDomain() + "/logout",
  scopes: "openid offline_access email",
  homeUri: cluburl[releaseType] + "/home",
};

export const apiDomain = {
  dev: "http://localhost:8014",
  sit: "https://datacapture-sit.api.dlp-k8s-npe.testservicensw.net",
  uat: "https://api-psm.g.testservicensw.net",
  it3: "https://datacapture-it3.testservicensw.net",
  it4: "https://datacapture-it4.testservicensw.net",
  load: "https://datacapture-load.npe-dlp-snsw.net",
  it1: "https://partner-portal-it1.testservicensw.net/club",
  bbounty: "https://datacapture-it6.npe-dlp-snsw.net",
  prod: "https://api.g.service.nsw.gov.au",
};

function getApiDomain() {
  return apiDomain[releaseType];
}

export const ClubManageApis = {
  signup: getApiDomain() + "/api/v1/datacapture/register",
  updateuser: getApiDomain() + "/api/v1/datacapture/updateuser",
  clublist: getApiDomain() + "/api/v1/datacapture/clubs",
  clubstatus: getApiDomain() + "/api/v1/datacapture/clubstatus",
  getclubdetail: getApiDomain() + "/api/v1/datacapture/club?clubId=",
  updateclubDetails: getApiDomain() + "/api/v1/datacapture/club",
  triggerstatus: getApiDomain() + "/api/v1/datacapture/triggerstatus",
  updateTriggerDetails: getApiDomain() + "/api/v1/datacapture/triggers",
  clubsolutionadmins: getApiDomain() + "/api/v1/datacapture/clubsolutionadmins",
  clubsolutionadminstatus:
    getApiDomain() + "/api/v1/datacapture/clubsolutionadminstatus",
  clubStatsTotal: getApiDomain() + "/api/v1/datacapture/clubtotal?clubname=",
  changepassword: getApiDomain() + "/api/v1/datacapture/changepassword",
  updateorganization: getApiDomain() + "/api/v1/datacapture/organization",
  clubsolutionusers: getApiDomain() + "/api/v1/datacapture/clubsolutionusers",
  clubsolutionuser: getApiDomain() + "/api/v1/datacapture/clubsolutionuser",
  clubsolutionuserdetails:
    getApiDomain() + "/api/v1/datacapture/clubsolutionuserdetails",
  accesstotal: getApiDomain() + "/api/v1/datacapture/accesstotal?clubname=",
  clubstats: getApiDomain() + "/api/v1/datacapture/clubstats",
  operationclaims: getApiDomain() + "/api/v1/datacapture/operationclaims",
  contactus: getApiDomain() + "/api/v1/datacapture/contactus",
  trigger: getApiDomain() + "/api/v1/datacapture/trigger",
  provider: getApiDomain() + "/api/v1/datacapture/provider",
  providerdetails: getApiDomain() + "/api/v1/datacapture/providerdetails",
  updateprovider: getApiDomain() + "/api/v1/datacapture/updateprovider",
  downloadTriggers:
    getApiDomain() + "/api/v1/datacapture/downloadtriggers?clubId=",
  feedback: getApiDomain() + "/api/v1/datacapture/feedback",
  registerlistitmes: getApiDomain() + "/api/v1/datacapture/getregisterlistitems",
};

export const viewChangeSwitch = {
  newMemberList: true,
  newProviderList: false,
  newTriggerList: false,
  shouldArchiveTriggers: true,
  shouldShowFeedbackOption: true,
  allowAnonymousContactUs: false,
};

export const UrlHelper = {
  getAuthUrl(nonce, code_challenge) {
    return (
      Config.authorizationEndpoint +
      "?response_type=code" +
      "&client_id=" +
      Config.clientId +
      "&scope=" +
      encodeURI(Config.scopes) +
      "&redirect_uri=" +
      Config.redirectUri +
      "&nonce=" +
      nonce +
      "&code_challenge=" +
      code_challenge +
      "&code_challenge_method=S256" +
      "&state=/"
    );
  },

  generateNonce(length) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  },

  getParams(url) {
    var regex = /[#?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match;

    while ((match = regex.exec(url))) {
      params[match[1]] = match[2];
    }

    return params;
  },
};

export const StorageHelper = {
  saveData(id, data) {
    if (typeof data !== "undefined") {
      localStorage.setItem(id, data);
    }
  },
  getData(id) {
    if (typeof id !== "undefined") {
      var urls = localStorage.getItem(id);
      return urls;
    } else {
      return null;
    }
  },
  deleteData(id) {
    localStorage.removeItem(id);
  },
};

export function getProfileInfo(access_token = null, key, auth0SubKey = null) {
  var payload = [];

  if (access_token != null) {
    payload = access_token.split(".");
  } else if (tokenExistsInStorage('access_token')) {
    payload = JSON.parse(StorageHelper.getData("id")).access_token.split(".");
  }

  if (payload.length === 3) {
    let jsonString = window.atob(payload[1]);
    const jsonObject = JSON.parse(jsonString);
    
    if (auth0SubKey) {
      return jsonObject[auth0userDetailsKey][auth0SubKey];
    }
    
    return jsonObject[key];
  } 

  return null;
}

function tokenExistsInStorage(key) {
    return StorageHelper.getData("id") != null &&
    JSON.parse(StorageHelper.getData("id")) != null &&
    JSON.parse(StorageHelper.getData("id"))[key] != null
}

function getUserRoleFromProfile(key = "permissions") {
  const userProfile = getProfileInfo(null, key);
  if (userProfile && userProfile !== null) {
    return userProfile[0];
  }
  return undefined;
}

export function isSystemRoles() {
  const userRole = getUserRoleFromProfile("permissions");
  return systemRoles.indexOf(userRole) > -1;
}

export function isProviderRoles() {
  const userRole = getUserRoleFromProfile("permissions");
  return providerRoles.indexOf(userRole) > -1;
}

export function loadData(
  url,
  method,
  data,
  isJson,
  tokenRequired = true,
  responseType = null
) {
  let token = null;
  if (tokenRequired === true) {
    token = getXIdToken();
  }

  if (isJson === null) {
    isJson = true;
  }
  return apiRequest(url, method, data, token, isJson, responseType).then(
    (result) => {
      return result;
    }
  ).catch((error) => error);
}

export function getXIdToken() {
  let token = null;
  if (tokenExistsInStorage('id_token')) {
    token = JSON.parse(StorageHelper.getData("id")).id_token;
  }
  return token;
}

export function getTokenHeader() {
  if (
    StorageHelper.getData("id") == null ||
    JSON.parse(StorageHelper.getData("id")) == null
  ) {
    this.Logout();
  }

  if (
    JSON.parse(StorageHelper.getData("id")).code != null &&
    StorageHelper.getData("code_verifier") != null
  ) {
    const data = new FormData();
    data.append("code", JSON.parse(StorageHelper.getData("id")).code);
    data.append("code_verifier", StorageHelper.getData("code_verifier"));
    data.append("redirect_uri", Config.redirectUri);
    data.append("client_id", "code.client");
    data.append("grant_type", "authorization_code");
    return data;
  } else if (JSON.parse(StorageHelper.getData("id")).refresh_token != null) {
    const data = new FormData();
    data.append(
      "refresh_token",
      JSON.parse(StorageHelper.getData("id")).refresh_token
    );
    data.append("scope", Config.scopes);
    data.append("redirect_uri", Config.redirectUri);
    data.append("client_id", "code.client");
    data.append("grant_type", "refresh_token");
    return data;
  } else {
    this.LogoutUser();
  }
}

export function ClearCache() {
  StorageHelper.deleteData("id");
  StorageHelper.deleteData("code_verifier");
}

export function LogoutUser() {
  ClearCache();
  window.location =
    Config.logoutEndpoint +
    "?response_type=code" +
    "&client_id=" +
    Config.clientId +
    "&redirect_uri=" +
    Config.redirectUri;
}

export function base64URLEncode() {
  function base64URLEncode(str) {
    return str
      .toString("base64")
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=/g, "");
  }

  function sha256(buffer) {
    return crypto.createHash("sha256").update(buffer).digest();
  }
  let verifier = base64URLEncode(crypto.randomBytes(32));
  let challenge = base64URLEncode(sha256(verifier));
  return [challenge, verifier];
}

export function isExpired(expiresAt) {
  let now = parseInt(Date.now() / 1000, 10);
  return (expiresAt - now) <= 0;
}

export function isUserLoggedIn() {
  try {
    if (
      StorageHelper.getData("id") != null &&
      JSON.parse(StorageHelper.getData("id")) != null
    ) {
      if (
        JSON.parse(StorageHelper.getData("id")).code != null &&
        StorageHelper.getData("code_verifier") != null
      ) {
        return true;
      } else if (
        JSON.parse(StorageHelper.getData("id")).refresh_token != null
      ) {
        return !isExpired(getProfileInfo(StorageHelper.getData("id").access_token,"exp"));
      } else {
        return false;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
}

export function apiRequest(
  url,
  method = "GET",
  data,
  token,
  isJson = true,
  responseType = null
) {
  const headers = {};
  if (token != null) {
    headers["Authorization"] = "Bearer " + token;
    headers["requestTimeStamp"] = new Date().toISOString();  // Use a real timestamp  //"2017-05-01T14:00:00.000Z";
    headers["transactionID"] = "1";
    headers["deviceID"] = "5fe1d74e-6d37-412d-835c-d2ee3bf12uat";
    headers["platform"] = "1";
    if (isJson) {
      headers["Content-Type"] = "application/json";
    }
  }
  if (responseType !== null && responseType !== undefined) {
    return axios({
      method: method,
      url: url,
      data: data,
      headers: headers,
      responseType: responseType,
    })
      .then((response) => response)
      // Typically if you are returning an error from Axious it should be
      //.catch((error) => {throw error});
      //  to throw the error back to the function who call it, as there is an issue where this is not handled in the function.
      // I am not making this change as a lot of requests expect the Axios request to be valid when it throws an error and
      //  that thrown error should be handled on return to the functions that uses apiRequest().
      // I have implemented a custom apiRequest() of this function elsewhere in the application when errors broke the page 
      //  redirecting to a blank page. If I make changes here, I fear it could break the functionality of other features and
      //    that is beyond the scope of my current changes, but developers need to be aware of this issue, as try-catch or 
      //    then-catch blocks calling this function will not be caught and it causes undefined behaviour. Errors should be handled
      //    in this function.
      // For more information: https://axios-http.com/docs/handling_errors & https://stackabuse.com/handling-errors-with-axios/ 
      .catch((error) => error);
  } else {
    return axios({ method: method, url: url, data: data, headers: headers })
      .then((response) => response)
      .catch((error) => error);
  }
}
